import { Pagination, Sort } from '@12stz/ui-kit/organisms/table';
import { HttpParams } from '@angular/common/http';
import { TableSearchQueryDto } from '@monorepo/shared';
import { FilterValue } from '@monorepo/ui/table';

export const tableParams = <T>(
  pagination: Pagination,
  sort?: Sort<T>,
  filters?: FilterValue[]
): HttpParams => {
  const obj: Partial<TableSearchQueryDto> = {
    page: {
      number: pagination.pageIndex + 1,
      size: pagination.limit,
    },
  };
  if (sort && sort?.direction !== '') {
    obj.sort = {
      type: sort.direction === 'desc' ? -1 : 1,
      by: sort.key as string,
    };
  }
  if (filters?.length) {
    obj.filters = {};
    filters.forEach((f) => {
      if (obj.filters) {
        obj.filters[f.field] = [
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            value: [f.value],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            matchMode: 'in',
          },
        ];
      }
    });
    obj.globalOperator = 'AND';
  }
  const stringifiedParams: { [key in keyof TableSearchQueryDto]?: string } = {
    page: JSON.stringify(obj.page),
  };
  if (obj.sort) {
    stringifiedParams.sort = JSON.stringify(obj.sort);
  }
  if (obj.filters) {
    stringifiedParams.filters = JSON.stringify(obj.filters);
    stringifiedParams.globalOperator = JSON.stringify(obj.globalOperator);
  }
  return new HttpParams({
    fromObject: stringifiedParams,
  });
};
